<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import useNavbarItems from "@/composables/getNavbarItems";

const { getNavbarItems } = useNavbarItems();
const items = computed(() => getNavbarItems());

const route = useRoute();
const isRoute = (name: string) => {
  return route.path.startsWith(`/main/${name}`);
};

</script>

<template>

  <nav class="fixed left-4 inset-x-4 lg:inset-x-8 bottom-4 lg:bottom-6 z-20 xl:static">
    <ul class="flex justify-center bg-interface-40 rounded-full px-4 md:px-4 xl:px-0">
      <li v-for="item in items">
        <RouterLink :class="{ 'active': isRoute(item.name) }" :to="{ name: item.name }" :title="item.title">
          <Icon :name="item.icon" class="xl:hidden" />
          <span class="hidden sm:block">{{ item.title }}</span>
        </RouterLink>
      </li>
    </ul>
  </nav>

</template>

<style scoped>

a {
  @apply h-16 sm:h-24 xl:h-14 px-4 md:px-10 rounded-full flex flex-col xl:flex-row justify-center items-center gap-2 whitespace-nowrap text-x-small md:text-small xl:text-medium text-ink-subtle xl:text-ink-base;

  &.active {

    @apply xl:bg-blue-90 xl:text-white text-ink-contrast;

    &::before {
      @apply hidden xl:block bg-green-100 rounded-full h-[10px] w-[10px];
      content: "";
    }
  }

  &:not(.active):hover {
        @apply text-blue-50;
    }
    
}
</style>

