<script setup lang="ts">
import type { FormFieldState } from "@/types";
import { ref, provide, type PropType } from "vue";

const props = defineProps({
  state: {
    type: String as PropType<FormFieldState>,
    default: "base",
  },
  label: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  helpText: {
    type: String,
    default: "",
  },
  errorText: {
    type: String,
    default: "",
  },
  mandatory: {
    type: Boolean,
    default: false
  }
});

const state = ref(props.state);
provide("state", props.state);
provide("mandatory", props.mandatory);
provide("checkValidity", (validity: ValidityState) => {
  state.value = validity.valid ? "valid" : "invalid";
});
</script>

<template>
  <div class="flex flex-col gap-2" :class="state">
    <label v-if="label" class="flex flex-wrap items-center gap-1">
      <span class="text-display-small font-medium">{{ label }}</span>
      <span class="text-display-xx-small font-normal text-danger-100" v-if="mandatory">*</span>
    </label>
    <div v-if="description">{{ description }}</div>
    <div>
      <slot></slot>
    </div>
    <div class="error-text text-small" v-if="state === 'invalid'">{{ errorText }}</div>
    <div class="help-text text-small text-ink-subtle" v-if="helpText">{{ helpText }}</div>
  </div>
</template>

<style scoped>
:deep(input:not([type="checkbox"]):not([type="radio"])),
:deep(select),
:deep(textarea), 
:deep([contenteditable]) {
  @apply w-full rounded-2xl border border-stroke-30 bg-interface-background-20 px-4 text-ink-contrast;

  &:focus-visible {
    @apply border-blue-50 bg-interface-20 outline-none;

    &::placeholder {
      @apply text-transparent;
    }
  }

  &::placeholder {
    @apply text-ink-subtle;
  }
}

.disabled {

  label,
  .help-text {
    @apply text-ink-disabled;
  }

  :deep(input),
  :deep(select),
  :deep(.textarea) {
    @apply border-stroke-20;
  }
}

.valid {
  .error-text {
    @apply !text-success-100;
  }

  :deep(input),
  :deep(select),
  :deep(.textarea) {
    @apply !border-success-100;
  }
}

.invalid {
  .error-text {
    @apply !text-danger-100;
  }

  :deep(input),
  :deep(select),
  :deep(.textarea) {
    @apply !border-danger-100;
  }
}
</style>