<script setup lang="ts">
import type { IconName, IconBackground } from "@/types";
import type { PropType } from "vue";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

defineProps({
  heading: {
    type: String,
    required: true,
  },
  icon: {
    type: String as PropType<IconName>,
    default: "warning",
  },
  iconBackground: {
    type: String as PropType<IconBackground>,
    default: "neutral",
  },
  largeHeading: Boolean
});

const emit = defineEmits(["close"]);

const target = ref(null);

onClickOutside(target, (event) => emit("close"));
</script>

<template>
  <div class="no-doc-scroll fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="dialog absolute flex flex-col w-11/12 sm:w-9/12 lg:w-8/12 xl:w-5/12 max-w-screen-md overflow-hidden rounded-2xl bg-white shadow-lg" ref="target">
      
      <!-- header -->
      <div class="flex flex-0 items-center justify-between border-b border-stroke-10">
        <div class="flex items-center gap-4 px-4 lg:px-6 py-4">
          <Icon :name="icon" size="medium" :background="iconBackground" />
          <div class="font-medium" :class="{ 'text-display-mediumplus lg:text-display-large' : largeHeading }">{{ heading }}</div>
        </div>
        <Button variant="secondary-minimal" @click="emit('close')">
          <Icon name="close" size="24" />
        </Button>
      </div>

      <!-- body -->
      <div class="p-4 lg:p-12 flex-1 overflow-y-auto">
        <slot></slot>
      </div>

      <!-- footer -->
      <div class="px-12 pb-12 shrink-0" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dialog {
  max-height: calc(100vh - 6rem);
}
</style>