<script setup lang="ts">
import type { FormFieldState, IconName } from "@/types";
import { inject, ref, type PropType } from "vue";

const state = inject("state") as FormFieldState;
const mandatory = inject("mandatory") as Boolean;
const checkValidity = inject("checkValidity") as (validity: ValidityState) => void;
const input = ref<HTMLInputElement | null>(null);
const model = defineModel();

const props = defineProps({
  id: {
    type: String,
    required: false,
  },
  icon: {
    type: String as PropType<IconName>,
  },
  iconRight: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    default: 'text'
  }
});

defineExpose({
  focus: () => input.value?.focus()
})

const onFocus = () => {
  if (props.type === "date" && input.value?.showPicker !== undefined) // not on Safari
    input.value!.showPicker();
}
const reportValidity = () => {
  checkValidity(input.value!.validity);
}
</script>

<template>
  <div class="relative flex items-center">
    <Icon v-if="icon" :name="icon" @click="onFocus" class="absolute scale-[75%] transform" :class="iconRight ? 'right-2' : 'left-2'" />
    <input ref="input"
      :type="type"
      :id="id"
      :placeholder="placeholder"
      class="h-16 flex-grow"
      :class="{ '!pl-12': !!icon && !iconRight, '!pr-12': !!icon && iconRight }"
      :disabled="state === 'disabled'"
      v-model="model"
      :required = "mandatory === true"
      @focus="onFocus"
      @keyup="reportValidity"
      @change="reportValidity"
      @invalid="reportValidity" />
  </div>
</template>

<style scoped>
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
</style>