<script setup lang="ts">
import { isMobile } from '@/composables/useBreakpoints';
import type { FeedbackModel } from '@/api-types';
import http from '@/http';
import { useConfirmDialog } from '@vueuse/core';
import type { AxiosResponse } from 'axios';
import { ref, reactive, computed } from 'vue'; // Import the reactive function from the vue package
const { isRevealed, reveal, cancel } = useConfirmDialog();

const feedbackModel = reactive<FeedbackModel>({
  rating: -1,
  text: '',
  createdOn: '',
});

const hoverRating = ref<number | null>(null);

let displayThankYouMessage = false;

const postFeedback = async (feedback: FeedbackModel) => {
  feedback.createdOn = new Date().toISOString();
  feedback.url = window.location.href;
  const response = await http.post<FeedbackModel, AxiosResponse>('/feedbacks', feedback);
  if (response.status >= 200 && response.status < 300) {
    feedbackModel.rating = -1;
    feedbackModel.text = '';
    feedbackModel.createdOn = '';
    feedbackModel.contactEmail = '';
    displayThankYouMessage = true;
  }
};

const mobile = isMobile();

const handleClose = () => {
  displayThankYouMessage = false;
  cancel();
};
</script>

<template>

  <Button class="z-10 !fixed right-4 lg:right-8 bottom-24 sm:bottom-32 xl:hidden" variant="primary" icon="chat" @click.prevent="reveal">
    <span class="hidden sm:inline">{{ $t('feedback.name') }}</span>
  </Button>

  <Button class="z-10 !fixed right-5 bottom-5 hidden xl:block" variant="secondary-solid" icon="chat" @click.prevent="reveal">
    <span>{{ $t('feedback.name') }}</span>
  </Button>

  <teleport to="body">
    <Transition>
      <Dialog v-if="isRevealed" :heading="$t('feedback.give')" @close="handleClose" icon="chat">

        <div v-if="!displayThankYouMessage">

          <p class="lg:px-16 text-center text-display-medium font-medium">
            {{ $t('feedback.question') }}
          </p>

          <div class="flex justify-center">
            <div>
              <div class="flex gap-1 lg:gap-3 text-light-neutral-50 mt-10">
                <button v-for="i in 5" :key="i" @click="feedbackModel.rating = i" @mouseenter="hoverRating = i" @mouseleave="hoverRating = null" :class="i <= (hoverRating ?? feedbackModel.rating!) ? 'text-green-90' : ''">
                  <Icon name="star" :size="mobile ? 'large' : '80'" />
                </button>
              </div>
              <div class="flex justify-between text-x-small text-ink-subtle">
                <span>{{ $t('feedback.noGood') }}</span>
                <span>{{ $t('feedback.good') }}</span>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-10 mt-14" v-if="feedbackModel.rating! > -1">
            <FormField :label="$t('feedback.getGood')">
              <TextArea v-model="feedbackModel.text!" :placeholder="$t('feedback.placeholder')" />
            </FormField>
            <FormField :label="$t('feedback.email')">
              <Input v-model="feedbackModel.contactEmail" :placeholder="$t('general.email')" />
            </FormField>
            <div class="flex justify-end mt-8 lg:mt-10">
              <Button variant="primary" @click.prevent="postFeedback(feedbackModel)">{{ $t('feedback.send') }}</Button>
            </div>
          </div>
        </div>

        <div v-if="displayThankYouMessage">
          <div class="flex flex-col justify-center items-center text-center gap-4">
            <Icon name="sparkle" size="huge" background="success" class="text-success-100 mt-8" />
            <Heading tag="h4" size="medium"> {{ $t('feedback.thanks') }} </Heading>
            <p class="text-x-small lg:text-small"> {{ $t('feedback.outro') }} </p>
          </div>
        </div>

      </Dialog>
    </Transition>
  </teleport>
</template>