import { useWindowSize } from "@vueuse/core";
import tailwindConfig from "@/../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import { computed, onMounted, onUnmounted, ref } from "vue";

export const isMobile =  () => {
  const twConfig = resolveConfig(tailwindConfig as any)
  const isMobile = ref(false);

  const updateMobile = () => {
    isMobile.value = window.innerWidth < parseInt(twConfig.theme.screens.md);
  }

  onMounted(() => {
    updateMobile();
    window.addEventListener('resize', updateMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateMobile);
  });

  return computed(() => isMobile.value);
};

export const isTablet =  () => {
  const twConfig = resolveConfig(tailwindConfig as any)
  const isTablet = ref(false);

  const updateTablet = () => {
    isTablet.value = window.innerWidth >= parseInt(twConfig.theme.screens.md) && window.innerWidth < parseInt(twConfig.theme.screens.lg);
  }

  onMounted(() => {
    updateTablet();
    window.addEventListener('resize', updateTablet);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateTablet);
  });

  return computed(() => isTablet.value);
}