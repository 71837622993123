<script setup lang="ts">
import type { FormFieldState, IconName } from "@/types";
import { computed, ref, nextTick, onMounted, inject, type PropType } from "vue";
import { onClickOutside } from "@vueuse/core";
import Input from "./Input.vue";

const state = inject('state') as FormFieldState;
const mandatory = inject("mandatory", false) as Boolean;
const checkValidity = inject("checkValidity", null as unknown) as (validity: ValidityState) => void;
const input = ref<HTMLInputElement | null>(null);
const model = defineModel();

const props = defineProps({
    id: {
        type: String,
        required: false
    },
    items: {
        type: Array as PropType<any[]>,
        required: true
    },
    placeholder: {
        type: String,
        default: ''
    },
    labelField: {
        type: String,
        default: 'label'
    },
    valueField: {
        type: String,
        default: 'value'
    },
    dropAutoWidth: {
        type: Boolean,
        default: false
    },
    dropRightAlign: {
        type: Boolean,
        default: false
    },
    searchable: {
        type: Boolean,
        required: false
    },
    icon: {
        type: String as PropType<IconName>,
        required: false,
        default: 'caret-down'
    }
});

const emit = defineEmits(['update:modelValue']);

const isOpen = ref(false);
const target = ref(null)
const toggle = ref<HTMLButtonElement | null>(null)
const search = ref<typeof Input | null>(null)
const filter = ref("");

const filteredItems = computed(() => {
    if (!filter.value) return props.items;
    const items = props.items.filter((item) => {
        return item[props.labelField].toLowerCase().includes(filter.value.toLowerCase());
    });
    if (items.length == 1)
        onSelect(items[0]);
    return items;
});

const selectedItem = computed(() => filteredItems.value.find(item => item[props.valueField] === model.value));

const onSelect = (item: any) => {
    emit('update:modelValue', item[props.valueField]);
    isOpen.value = false;
    toggle.value?.focus();
    nextTick(() => {
        filter.value = '';
        if (checkValidity) {
            checkValidity(input.value!.validity);
        }
    });
}

const toggleOpen = () => {
    isOpen.value = !isOpen.value;
    if (isOpen.value)
        nextTick(() => {
            if (search.value)
                search.value.focus();
        });
}

onClickOutside(target, event => isOpen.value = false)

const reportValidity = () => {
    checkValidity(input.value!.validity);
}

onMounted(() => {
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.offsetWidth) + 'px');
});   
</script>

<template>
    <div class="relative" ref="target">
        <input ref="input" class="absolute bottom-0 h-0 opacity-0" v-model="model" :required="mandatory === true" @invalid="reportValidity" />
        <button class="w-full" :class="{ opened: isOpen }" :id="id" ref="toggle" @click.prevent="toggleOpen" :disabled="state === 'disabled'">
            <slot name="toggle" :item="selectedItem">
                <div class="toggle flex textarea">
                    <span class="w-full text-left text-wrap text-ink-contrast">
                        {{ selectedItem ? selectedItem[labelField] : placeholder }}
                    </span>
                    <Icon :name="icon" size="24" />
                </div>
            </slot>
        </button>
        <Transition>
            <div v-if="isOpen" :class="{ 'w-[calc(100vw-2rem-var(--scrollbar-width))] sm:w-full': !dropAutoWidth, 'right-0': dropRightAlign }" class="mt-2 absolute z-10 rounded-2xl bg-white border border-stroke-10 max-h-80 overflow-y-auto">
                <ul>
                    <li v-if="searchable" class="p-4">
                        <Input ref="search" icon="magnifying-glass" :placeholder="$t('general.search')" v-model="filter" />
                    </li>
                    <li v-for="(item, index) in filteredItems" :key="index">
                        <slot name="item" :item="item" :select="onSelect">
                            <button class="item w-full" @click.prevent="onSelect(item)">
                                {{ item[labelField] }}
                            </button>
                        </slot>
                    </li>
                </ul>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.toggle {
    @apply h-16 w-full flex items-center rounded-2xl border border-stroke-30 bg-interface-background-20 pl-6 pr-4 whitespace-nowrap text-ink-subtle bg-no-repeat;
}

button:has(.toggle):focus-visible,
button:has(.toggle).opened {
    @apply outline-none;

    .toggle {
        @apply border border-blue-50 bg-interface-20 outline-none;
    }
}

.item {
    @apply flex items-center p-4;

    &:hover,
    &:focus-visible {
        @apply bg-interface-20 outline-none;
    }
}
</style>