<script setup lang="ts">
import { useMainStore } from "@/stores/main";
import { computed, provide, ref } from "vue";
import type { FormFieldState, IconName } from "@/types";

import { useI18n } from "vue-i18n";
const { t, availableLocales } = useI18n();

type Language = {
  iso: string;
  shortName: string;
  name: string;
    icon: IconName;
};

const mainStore = useMainStore();
const toggle = ref<HTMLButtonElement>();

const languages = computed(() => {
  return availableLocales.map((locale) => {
    switch (locale) {
      case "no": return {
        iso: locale,
        shortName: "No",
        name: t("general.language_" + locale),
        icon: locale as IconName
      } as Language
      case 'en': return {
        iso: locale,
        shortName: "En",
        name: t("general.language_" + locale),
        icon: locale as IconName
      } as Language
    }});
});

// not very intuitive but Select requires ut from FormField which we're not using here ... consider refactoring
provide("state", "base" as FormFieldState);
// ---

</script>
<template>
  <Select :dropAutoWidth="true" :dropRightAlign="true" class="w-28" label-field="shortName" value-field="iso" :items="languages" :model-value="$i18n.locale" @update:model-value="(val: string) => mainStore.setLanguage($i18n.locale = val)">
    <template #toggle="{ item }: { item: Language }">
      <div class="toggle" ref="toggle">
        <Icon class="rounded-full" :name="$i18n.locale as IconName" />
        <span class="pl-2">{{ item?.shortName }}</span>
        </div>
    </template>
    <template #item="{ item, select }">
      <button class="item w-full" @click.prevent="() => { select(item); toggle?.focus(); }">
        <Icon class="rounded-full p-[6px] -ml-[6px]" :name="item.icon" />
        <span class="pr-12 -ml-[6px]">{{ item.name }}</span>
      </button>
    </template>
  </Select>
</template>

<style scoped>
.toggle {
  @apply h-14 w-full flex items-center rounded-full bg-interface-40 px-6 whitespace-nowrap text-black;

  &:hover {
    @apply bg-interface-50;
}
}

button:has(.toggle):focus-visible {
  @apply outline-none;

  .toggle {
    @apply bg-interface-20;
  }
}

.item {
  @apply flex items-center p-4 gap-2 outline-none;

  &:focus-visible,
  &:hover {
    @apply bg-interface-20;
  }
}
</style>