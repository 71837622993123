/** @type {import('tailwindcss').Config} */

// https://github.com/tailwindlabs/tailwindcss/blob/master/stubs/config.full.js

const pxToRem = (px: any, base = 16) => `${px / base}rem`;

const colors = {
  white: "#FFF",
  black: "#000",
  transparent: "transparent",
  green: {
    100: "#2ACB80",
    90: "#29F285",
    80: "#54F59D",
    70: "#7EF7B6",
    60: "#BFFBDA",
    50: "#DDFDEC",
    40: "#F5FEF9",
  },
  blue: {
    100: "#001633",
    90: "#032454",
    80: "#194885",
    70: "#1857A9",
    60: "#1769D3",
    50: "#1574EF",
    40: "#2E86FA",
    30: "#82B7FC",
    20: "#B4D4FD",
    10: "#CEE3FD",
  },
  "light-neutral": {
    60: "#C6D2E1",
    50: "#D4DFED",
    40: "#E8EEF5",
    30: "#F4F7FA",
    20: "#FBFCFE",
    10: "#FFFFFF",
  },
  "dark-neutral": {
    100: "#1B1C1D",
    90: "#2E2E2E",
    80: "#565657",
    70: "#707071",
    60: "#89898A",
    50: "#A3A3A4",
  },
  danger: {
    100: "#77183F",
    50: "#C27092",
    10: "#F4C8DA",
  },
  success: {
    100: "#115535",
    50: "#70C29B",
    10: "#C8F4DF",
  },
  warning: {
    100: "#58570E",
    50: "#C2C070",
    10: "#F4F3C8",
  },
  info: {
    100: "#032454",
    50: "#B4D4FD",
    10: "#CEE3FD",
  },
};

export default {
  content: ["./index.html", "./src/**/*.{vue,js,ts,jsx,tsx}"],
  theme: {
    fontFamily: {
      sans: ["Poppins", "sans-serif"],
    },
    fontWeight: {
      normal: "400",
      medium: "500",
    },
    fontSize: {
      "x-small": [pxToRem(14), { lineHeight: pxToRem(18) }],
      small: [pxToRem(16), { lineHeight: pxToRem(20) }],
      medium: [pxToRem(18), { lineHeight: pxToRem(22) }],
      large: [pxToRem(24), { lineHeight: pxToRem(30) }],
      "display-xx-small": [pxToRem(14), { lineHeight: pxToRem(18) }],
      "display-x-small": [pxToRem(16), { lineHeight: pxToRem(20) }],
      "display-small": [pxToRem(18), { lineHeight: pxToRem(22) }],
      "display-medium": [pxToRem(24), { lineHeight: pxToRem(30) }],
      "display-mediumplus": [pxToRem(28), { lineHeight: pxToRem(35) }],
      "display-large": [pxToRem(40), { lineHeight: pxToRem(50) }],
      "display-huge": [pxToRem(48), { lineHeight: pxToRem(60) }],
    },
    colors: {
      ...colors,
      "interface-background": {
        10: colors["light-neutral"][40],
        20: colors["light-neutral"][20],
      },
      interface: {
        10: colors["light-neutral"][10],
        20: colors["light-neutral"][30],
        30: colors["light-neutral"][40],
        40: colors["light-neutral"][50],
        50: colors["light-neutral"][60],
      },
      stroke: {
        10: colors["light-neutral"][40],
        20: colors["light-neutral"][50],
        30: colors["light-neutral"][60],
      },
      ink: {
        contrast: colors["dark-neutral"][100],
        base: colors["dark-neutral"][90],
        subtle: colors["dark-neutral"][80],
        disabled: colors["dark-neutral"][60],
        deactivated: colors["dark-neutral"][50],
      },
      interactive: {
        primary: colors.green[90],
        "primary-hover": colors.green[100],
        secondary: colors["dark-neutral"][90],
        "secondary-hover": colors["dark-neutral"][100],
      },
      link: colors.blue[60],
    },
    extend: {
      animation: {
        loader: 'loader 0.6s infinite alternate'
      },
      keyframes: {
        loader: {
          to: {
            //opacity: 0.8,
            transform: 'translate3d(0, -.5rem, 0)'
          }
        }
      }
    },
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
    },
  },
};
